import { upload } from '@/assets/js/upload'
// import { getToken } from '@/utils/auth'
const state = {
}
const mutations = {

}

const actions = {
    upload({ }, data) {
        return new Promise((resolve, reject) => {
            upload(data).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
